/* Modal Overlay */
.map-picker-modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 998;
    pointer-events: auto;
}

/* Modal Container */
.map-picker-modal {
    position: relative;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    max-width: 50vw; /* Adjusted for consistency with other modals */
    width: 100%;
    max-height: 85vh; /* Matches the height of other modals */
    height: 100%;
    padding: 2vh;
    overflow-y: auto;
    z-index: 999;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

/* Modal Title */
.map-picker-modal-title {
    align-self: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5vh;
    color: #333;
}

/* Close Icon */
.close-map-picker-icon {
    position: absolute;
    top: 1.5vh;
    right: 1.5vw;
    font-size: 1.8rem;
    color: gray;
    cursor: pointer;
}

/* Ensure AddressMapPicker occupies proper space */
.address-map-picker {
    align-self: center;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Selected Address Styling */
.selected-address-container {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .selected-address {
    font-size: 1rem;
    color: #333;
  }
  
  .address-line {
    display: block;
    margin: 5px 0;
  }
  
  .icon-flag {
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url("../../../../public/icon-flag.jpg") no-repeat center center / contain;
    margin-right: 5px;
  }

/* Center the Confirm Address button */
.address-map-picker-buttons {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 20px; /* Add some space above the button */
}

/* Style the Confirm Address button */
.address-map-picker-buttons .btn {
  width: 60%; /* Make the button wider */
  padding: 10px 0; /* Increase vertical padding for better appearance */
  font-size: 1.2rem; /* Adjust the font size for better readability */
  border-radius: 5px; /* Add some rounding to match the modal's style */
  text-align: center; /* Ensure text is centered */
}
  

