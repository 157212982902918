.add-address-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
  }
  
  .add-address-modal {
    position: relative;
    max-width: 60vw;
    width: 100%;
    max-height: 60vh;
    height: 100%;
    border-radius: 10px;
  }
  
  .add-address-modal-title {
    font-size: 1.5rem;
  }

  .close-add-address-modal-icon {
    position: absolute;
    top: 1.5vh;
    right: 1.5vw;
    font-size: 1.8rem;
    color: gray;
    cursor: pointer;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .add-address-content-container{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 70%;
  }

  .add-address-content-container > * {
    padding: 15px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .add-address-image {
    width: 80%;
    height: 100%;
  }