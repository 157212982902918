.address-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 998;
    pointer-events: auto;
}

.modal-title {
    align-self: center;
    padding: 2vh;
}

.address-modal {
    position: relative;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    max-width: 50vw;
    width: 100%;
    max-height: 70vh;
    height: auto;
    padding: 2vh;
    overflow-y: auto;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure the button stays at the bottom */
}

/* Close icon */
.close-icon {
    position: absolute;
    top: 1vh;
    right: 20px; 
    font-size: 1.8rem;
    color: gray;
    cursor: pointer;
}

.address-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5vh;
    margin-bottom: auto; /* Push the button to the bottom */
    padding-bottom: 3vh;
}

.address-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.address-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5vh;
    margin-top: 0.8vh;
    cursor: pointer;
    border: 1px solid #4b4a4a;
    border-radius: 5px;
    transition: background-color 0.3s;
    max-width: 40vw;
    width: 100%;
}

.address-item:hover {
    background-color: #f4f4f4;
}

.remove-icon {
    color: #757272; /* Red color for the remove icon */
    cursor: pointer;
    transition: color 0.3s ease;
    margin-left: 15px;
}

.remove-icon:hover {
    color: #4d4b4b; /* Darker red on hover */
}

/* Button container ensures alignment */
.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5vh 0;
}

.btn {
    align-self: center;
    width: auto; /* Adjusts the width to fit the content */
    white-space: nowrap; /* Ensures the text stays in one line */
    padding: 10px 20px;
    font-size: 1rem;
}
