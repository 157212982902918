.types-card-container {
  display: flex;
  gap: 2vw;
  padding-bottom: 2vh;
  padding-top: 2vh;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #f4f4f4;
  height: 20vh;
  align-items: stretch; /* Ensures all children take up the full height */
}

.types-icon-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 10vw;
  height: 100%; /* Ensure it takes up the full height of the container */
  text-align: center;
  transition: transform 0.3s ease;
}

.types-icon-card:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

.types-card-image {
  width: 50%; /* Make it a percentage of the container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
  max-height: 60%; /* Ensure it doesn’t exceed a certain percentage of its parent */
}

.types-card-title {
  margin-top: 1vh;
  font-size: 1.5vh;
  color: #333;
  font-weight: bold;
  max-height: 20%; /* Ensure text does not overflow */
  overflow: hidden;
  text-overflow: ellipsis; /* If text overflows, it will be truncated */
}

.card-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
  margin: 0 auto;
  padding: 2vh 0;
}

.container-title {
  font-size: 1.5vw;
  margin-bottom: 2vh;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.title-icon {
  margin-right: 0.5vw;
  font-size: 1.5vw;
  color: #333;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2vw;
  justify-content: flex-start;
  width: 100%;
}
