.footer-container {
    background-color: #333; /* Same color as top navigation */
    color: white;
    padding: 4vh 6vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

.footer-company h2 {
    font-size: 2vw;
    margin-bottom: 1vh;
    padding-left: 2vh;
    padding-bottom: 2vh;
  }
  
.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }

.footer-content > * {
    padding-left: 1vw;
    padding-right: 1vw;
}
  
  .footer-collaborate h3, 
  .footer-links h3, 
  .footer-social h3, 
  .footer-apps h3, 
  .footer-policies h3  {
    font-size: 1vw;
    margin-bottom: 1vh;
  }
  
  .footer-collaborate p, 
  .footer-links ul, 
  .footer-social ul,
  .footer-policies ul  {
    font-size: 0.8vw;
  }
  
  .footer-links ul,
  .footer-social ul,
  .footer-policies ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-links li, 
  .footer-social li,
  .footer-policies li {
    margin-bottom: 1vh;
  }
  
  .footer-links a, 
  .footer-social a, 
  .footer-policies a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-links a:hover,
  .footer-social a:hover, 
  .footer-policies a:hover {
    color: #ddd;
  }

  .footer-apps .app-icons {
    display: flex;
    flex-direction: column;
    gap: 1vw;
  }
  
  .app-icon {
    width: 5vw;
    height: auto;
    cursor: pointer;
    padding-top: 1vh;
  }
  
  .footer-policies ul {
    padding-top: 1vh;
  }
  