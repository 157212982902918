.main-text {
    font-size: 5vh;
    margin-left: 2vh;
}
.gradient-text {
    background: linear-gradient(to right, white, rgb(0, 0, 0));
    -webkit-background-clip: text;
    background-clip: text;
    clip: text;
    -webkit-text-fill-color: transparent;
    width: 40vw;
}

.navbar-middle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
    position: absolute;
    width: 40vw;
    margin-left: 30vw;
}

/* Style for the address section */
.address-section {
    display: flex;
    align-items: center;
    color: white;
}

/* Style the house icon and arrow */
.house-icon, .arrow-icon {
    margin: 0 5px;
}

.house-icon {
    color: rgb(177, 171, 171)
}

.arrow-icon {
    cursor: pointer;
}

.pre-address-text {
    font-size: 1rem;
    color: rgb(177, 171, 171)
}

.address-text {
    font-size: 1vw;
    padding-left: 0.5vw;
    cursor: pointer;
}

.navbar-right {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.login-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

/* Style for auth buttons */
.auth-buttons {
    display: flex;
}

.auth-buttons a {
    padding: 8px 15px;
    font-size: 1rem;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    width: 50%;
}

.auth-buttons .btn-outline-light {
    border: 1px solid white;
    color: white;
}

.auth-buttons .btn-light {
    color: #000;
}

.auth-buttons .btn:hover {
    opacity: 0.8;
}