/* src/components/Input/input.css */
.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.input-group {
  position: relative;
  width: 50%;
  max-width: 600px;
}

.input-group .form-control {
  border-radius: 20px;
  border-color:#f4f4f4;
  font-size: 1vw;
  background-color: #f4f4f4;
}

.input-group-prepend .input-group-text {
  background-color: transparent;
  border: none;
  padding-left: 0;
  padding-right: 10px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.input-group-text-span {
  display: flex;
  align-items: center;
  width: 40px;
  justify-content: center;
  border-radius: 20px;
  background-color: #f4f4f4;
}

.search-icon {
  color: gray;
  font-size: 1.2rem;
}

.clear-icon {
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
  color: gray;
  font-size: 1.5rem;
  background: transparent;
  z-index: 10;
}

/* Ensure border radius isn't removed when icons are present */
.input-group-prepend {
  display: flex;
  align-items: center;
}

.input-group .form-control,
.input-group-prepend .input-group-text {
  border-radius: 20px;
  border-left: none;
}

