.business-card {
  display: flex;
  flex-direction: column; /* Changed to stack image and text */
  width: 18vw;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  margin: 1vh 0;
}

.business-card:hover {
  transform: translateY(-10px);
  cursor: pointer;
}

/* Container for the image and service tag */
.business-card-image-container {
  position: relative;
  width: 100%;
  height: 15vh;
}

.business-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Service tag styling */
.business-card-service {
  position: absolute;
  bottom: 5px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 0.8vw;
  padding: 2px 8px;
  border-radius: 5px;
  font-weight: bold;
}

/* Business name styling */
.business-card-title {
  font-size: calc(0.6vw + 0.6vh); /* Responsive font size */
  color: #333;
  font-weight: bold;
  text-align: left;
  margin: 0.3vh 0 0 0;
  padding: 0 0.5vw;
}

.business-card-rating {
  display: flex;
  justify-content: flex-start;
  margin-left: 0.5vw;
  align-items: center;
  font-size: calc(0.6vw + 0.4vh); /* Responsive font size */
  color: #666;
  margin-top: 0.3vh;
  margin-bottom: 1vh;
}

.thumbs-up-icon {
  color: #4CAF50;
  margin-right: 0.5vw;
}

.rating-percentage {
  font-weight: bold;
}

.review-count {
  margin-left: 0.5vw;
}
