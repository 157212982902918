/* src/components/CollaborateSection/CollaborateSection.css */

.collaborate-section {
    margin-top: 5vh;
    padding: 4vh 2vw;
    background-color: #e6f7f2;
    text-align: center;
}

.collaborate-section h2 {
    font-size: 2.5vw;
    margin-bottom: 3vh;
    font-weight: bold;
    color: #333;
}

.collaborate-card-container {
    display: flex;
    justify-content: space-around;
    gap: 2vw;
    padding: 2vh;
    flex-wrap: wrap;
}

.collaborate-card {
    background-color: white;
    border-radius: 10px;
    width: 20vw;
    padding: 3vh 1vw;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
}

.collaborate-card:hover {
    transform: translateY(-5px);
}

.collaborate-image {
    width: 60%;
    height: auto;
    border-radius: 50%;
    margin-bottom: 1.5vh;
    border: 3px solid #004f32;
}

.collaborate-card h3 {
    font-size: 1.8vh;
    font-weight: bold;
    margin-bottom: 1vh;
}

.collaborate-card p {
    font-size: 1.5vh;
    color: #555;
    margin-bottom: 2vh;
}

.collaborate-button {
    background-color: #008060;
    color: white;
    padding: 1vh 2vw;
    font-size: 1.5vh;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.collaborate-button:hover {
    background-color: #005940;
}
